import ClientUserStore from 'shared/src/user/client.user.store';
import type { UserWithJoins } from 'shared/definitions/user-object-definitions';
import type { Load } from '@sveltejs/kit';
import type { Writable } from 'svelte/store';
import { openModal } from './modals.store';
import { browser } from '$app/env';
import { getServerUserStore } from './serverUserStore';

let cachedClientUserStore: ClientUserStore | undefined;

export function getApiPaths() {
  return {
    baseUrl: import.meta.env.VITE_API_URL,
    webSocketUrl: import.meta.env.VITE_WS_URL,
  };
}

export async function getUserStore({
  userWithJoins,
  fetch,
}: {
  userWithJoins?: UserWithJoins;
  fetch?: (info: RequestInfo, init?: RequestInit | undefined) => Promise<Response>;
}): Promise<ClientUserStore> {
  // If we're running on the client and there's a cache, return it.
  if (typeof window !== 'undefined' && cachedClientUserStore) {
    return cachedClientUserStore.loadingPromise;
  }

  // If we're running on the server, and a cache exists, and it matches the userWithJoins id,
  // return it
  if (
    typeof window === 'undefined' &&
    userWithJoins &&
    cachedClientUserStore &&
    userWithJoins.id === cachedClientUserStore.id
  ) {
    return cachedClientUserStore.loadingPromise;
  }

  const { baseUrl: apiUrl, webSocketUrl: websocketUrl } = getApiPaths();
  const userStore = new ClientUserStore(
    {
      apiUrl,
      websocketUrl,
    },
    userWithJoins,
    fetch
  );
  if (!userWithJoins) {
    await userStore.loadingPromise;
  }

  cachedClientUserStore = userStore;
  // If we're on the client, we watch for logout by polling the api.
  watchForLogout(userStore);
  return userStore;
}

export const updateSessionUserWithJoins = (
  userWithJoins: UserWithJoins,
  session: Writable<App.Session>
) => {
  if (browser) {
    session.set({ user: userWithJoins });
    cachedClientUserStore?.update(userWithJoins);
  }
};

export const invalidateUserStore = (session: Writable<App.Session>) => {
  cachedClientUserStore = undefined;
  if (browser) {
    session.set({ user: undefined });
  }
};

export const userStorePageLoadFunction: Load = async ({ session: loadSession, fetch }) => {
  const clientUserStore = await getUserStore({ userWithJoins: loadSession.user, fetch });
  return {
    props: {
      clientUserStore,
    },
  };
};

export const serverOrClientUserStorePageLoadFunction: Load = async ({ session, fetch, url }) => {
  const serverUserStore =
    !browser && session.user ? getServerUserStore({ userWithJoins: session.user }) : undefined;

  const clientUserStore = !serverUserStore
    ? await getUserStore({ userWithJoins: session.user, fetch })
    : undefined;

  return {
    props: {
      clientUserStore,
      serverUserStore,
    },
  };
};

let refreshUserStoreOccaisionally = true;
// Let's be sure the user is still logged in every 10 seconds.
const isLoggedInCheckDelayMS = 10 * 1000;
// Let's refresh the client store every 120 seconds (two minutes)
const refreshClientStoreEveryNthIsLoggedInCheck = 12;

function watchForLogout(userStore: ClientUserStore) {
  if (typeof window === 'undefined') return;
  let lastResponse = true;
  let responseCount = 1;

  window.setInterval(() => {
    userStore.api
      .isLoggedInAsCurrentUser()
      .then((response) => {
        /**
         * {
         *   isLoggedInAsCurrentUser: boolean;
         *   userId: string;
         *   anonymous: boolean;
         * }
         */
        if (response.isLoggedInAsCurrentUser !== true && lastResponse === true) {
          openModal('sessionExpiredMustLoginModal');
        } else if (response.isLoggedInAsCurrentUser) {
          // We are logged in as the current user -- let's poll for a complete update
          responseCount++;
          if (
            refreshUserStoreOccaisionally &&
            responseCount % refreshClientStoreEveryNthIsLoggedInCheck === 0
          ) {
            userStore.api.refresh();
          }
        }
        lastResponse = !!response.isLoggedInAsCurrentUser;
      })
      // We don't especially care if it gives an error -- maybe server is
      // down briefly, we'll trust other areas to throw errors if things don't
      // work there. For now, let's not interrupt the user's work.
      .catch(() => {});
  }, isLoggedInCheckDelayMS);
}

export function pauseUserStoreRefresh() {
  refreshUserStoreOccaisionally = false;
}

export function resumeUserStoreRefresh() {
  // Only valid to reset to true on client side.
  refreshUserStoreOccaisionally = true && typeof window !== 'undefined';
}
