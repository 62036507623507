/* eslint-disable-next-line import/no-cycle */
import { action, observable, runInAction, makeObservable } from 'mobx';
import type Stripe from 'stripe';
import type { AuthFlowParameters } from 'definitions/auth-object-definitions';
import type {
  UserWithJoins,
  ClientUserWithCredentials,
  UserRecord,
  StudentUserRecord,
  Accommodation,
} from '../../definitions/user-object-definitions';
import type { UserRegistrationBody } from '../../definitions/local-registration-obj-definition';
import type {
  UProgressRequestData,
  UProgressRequestResponse,
} from '../../definitions/progress-data';
import type { LessonRecord } from '../../definitions/lesson-record';
import type { Subscription } from '../../definitions/subscription-definitions';
import type {
  GroupLicense,
  TestingLicense,
  LicenseUsage,
  TestingLicenseRate,
  LicenseRouterPricingResponse,
  LicensePurchaseRequestBody,
  TestingLicensePurchaseRequestBody,
} from '../../definitions/license-definitions';
import type { StripeInvoice } from '../../definitions/stripe-charge-definition';
import type {
  CheckpointStats,
  CheckpointResults,
  WeightedAnswerAndData,
  SavedCustomCheckpoint,
} from '../../definitions/checkpoint-definitions';
import type { UMessage } from '../../definitions/message-definition';
import type {
  SectionRecord,
  SectionRecordWithCount,
  SectionRecordWithJoins,
} from '../../definitions/section-definitions';
import ApiQueue from './apiQueue';
import type ClientUserStore from '../user/client.user.store';
import { TestAttemptStore } from '../user/tests/checkpoint.attempt.store';
import SectionStore from '../section/section.store';
import { SocketDispatcher } from './socketDispatcher';
import type { UserStore } from '../user/user.store';
import type { CustomTestResultsWithNames } from '../user/tests/rootless.test.attempt.store';
import { RootlessTestAttemptStore } from '../user/tests/rootless.test.attempt.store';
import type { InstitutionInvite } from '../../definitions/institution-definitions';
import type { GoogleClassroomCourseWithLMSConnection } from '../../definitions/google-classroom-definitions';
import { omit } from '../utils/omit';
import { isObject } from '../utils/isObject';
import { isString } from '../utils/isString';
import type { ApiServiceConfig } from '../utils/api/api';
import { API, toURLString } from '../utils/api/api';
import type { CleverSectionWithLMSConnection } from 'definitions/clever-section-definitions';

export class RestAPIServiceClass extends API {
  public ApiQueue = new ApiQueue();

  constructor(
    config: ApiServiceConfig,
    private root: ClientUserStore,
    cookiedFetch?: typeof fetch
  ) {
    super(config, cookiedFetch);

    makeObservable(this);
  }

  refresh() {
    return this.$get<UserWithJoins>('api/users', { joinAll: true }).then((userWithJoins) => {
      this.root.update(userWithJoins);
      return userWithJoins;
    });
  }

  login(username: string, password: string, parameters: AuthFlowParameters = {}) {
    return super.login(username, password, parameters).then((userWithJoins) => {
      runInAction(() => {
        this.root.update(userWithJoins);
      });
      return userWithJoins;
    });
  }

  register(data: UserRegistrationBody) {
    return super.register(data).then((userWithJoins) => {
      this.root.update(userWithJoins);
      return userWithJoins;
    });
  }

  upgrade(
    data: Pick<UserRegistrationBody, 'phone' | 'schoolName' | 'teacherUrl' | 'teacherImageUrl'>
  ) {
    return this.$post<ClientUserWithCredentials>('auth/upgrade', data).then((userRecord) => {
      this.root.setRoles(userRecord.roles);
      return userRecord;
    });
  }

  fetchUser(data?: { id: string }) {
    if (data?.id) {
      return this.$get<UserWithJoins>(`api/users/${data.id}`, data);
    }

    return this.$get<UserWithJoins>('api/users', data);
  }

  putUser<T extends Partial<UserRecord> & { password2?: string }>(
    id: string,
    data: T
  ): Promise<Omit<T, 'password' | 'password2'> & { updatedAt: string }> {
    const route = `api/users/${id}`;
    return this.$put<Omit<T, 'password' | 'password2'> & { updatedAt: string }>(
      route,
      omit(data, 'id')
    );
  }

  updateStudentPassword({
    studentId,
    sectionId,
    password,
    password2,
  }: {
    studentId: string;
    sectionId: string;
    password: string;
    password2: string;
  }) {
    return this.$put<'ok'>(`api/sections/${sectionId}/users/${studentId}/password`, {
      password,
      password2,
    });
  }

  @observable _progressSent: boolean = false;

  @action private setProgressSent(bool: boolean) {
    this._progressSent = bool;
  }

  /**
   * Send progress data (lesson, skills or both) and update the results on the
   * user object.
   * @param data
   */
  sendProgressData(data: UProgressRequestData) {
    this.setProgressSent(true);
    const route = 'api/progress';
    return this.ApiQueue.queue<UProgressRequestResponse>(() => this.$post(route, data), {
      actionText: 'saving work',
      maxDelayS: 15,
      triesBeforeWarning: 3,
    }).then((response) => {
      if (response.lesson) {
        const lesson = this.root.lessonProgress.lessonsById[response.lesson.lessonId];
        if (lesson && lesson.type === 'lesson') lesson.update(response.lesson);
      }
      if (response.skills) {
        response.skills.forEach((skill) => {
          const userSkill = this.root.knowledgeProgress.skillsById[skill.skillId];
          if (userSkill) userSkill.update(skill);
        });
      }
      // GCR TODO: Backend should send a full uPoints record so we can
      // calculate time spent, among other things, on the student's view
      if (response.uPoints) {
        this.root.setUPoints(response.uPoints.points);
      }
      if (response.pagesRemaining) {
        this.root.updatePagesRemaining(response.pagesRemaining);
      }
      return response;
    });
  }

  fetchLessonProgressData(lessonId: string) {
    return this.$get<LessonRecord[]>(`api/lessons/${lessonId}`).then(
      (lessonRecords) => lessonRecords[0]
    );
  }

  /**
   * Calculate sales tax on a subscription purchase
   * @param total - as cccc
   * @param zip - as string, because some start with 0.
   */
  calculateSalesTax(total: number, zip: string) {
    return this.$post<{ beforeTax: number; taxAmount: number; taxPercent: number; total: number }>(
      'api/payments/calculate-tax',
      { total, zip }
    );
  }

  subscribe(planId: string, country: string, zip: string, token: string) {
    return this.$post<Subscription>('api/payments/subscribe', { planId, token, country, zip }).then(
      (subscription) => {
        this.root.addOrUpdateSubscription(subscription);
        this.root.setIsSubscribed(true);
        return subscription;
      }
    );
  }

  previewSubscriptionInvoice({
    country,
    planId,
    zip,
  }: {
    country: string;
    planId: string;
    zip: string;
  }): Promise<Stripe.Response<Stripe.Invoice>> {
    return this.$post('/api/payments/subscribe/preview', { country, planId, zip });
  }

  previewLicenseInvoice(
    quantity: number,
    startDate: Date,
    months: number,
    country: string,
    zip: string
  ): Promise<Stripe.Response<Stripe.Invoice>> {
    const licensePurchaseRequestBody: LicensePurchaseRequestBody = {
      quantity,
      startDate: startDate.toString(),
      country,
      zip,
      months,
    };

    return this.$post<Stripe.Response<Stripe.Invoice>>(
      'api/payments/purchase-group-license/preview',
      licensePurchaseRequestBody
    );
  }

  executeLicensePayment(
    quantity: number,
    startDate: Date,
    months: number,
    country: string,
    zip: string,
    token: string
  ) {
    const licensePurchaseRequestBody: LicensePurchaseRequestBody = {
      quantity,
      startDate: startDate.toString(),
      country,
      zip,
      token,
      months,
    };

    return this.$post<GroupLicense>(
      'api/payments/purchase-group-license',
      licensePurchaseRequestBody
    ).then((groupLicense) => {
      const institution = this.root.teachesForInstitutions.find(
        (i) => i.id === groupLicense.userId
      );
      if (institution) {
        runInAction(() => {
          institution.addOrUpdateLicense(groupLicense);
        });
      } else {
        // If this is a user's first license, they've just now become an institution.
        this.refresh()
          .then(() => this.getLicenseUsage())
          .then(() => groupLicense);
      }
      return groupLicense;
    });
  }

  previewTestingLicenseInvoice(
    quantity: number,
    startDate: Date,
    country: string,
    zip: string
  ): Promise<Stripe.Response<Stripe.Invoice>> {
    const licensePurchaseRequestBody: TestingLicensePurchaseRequestBody = {
      quantity,
      startDate: startDate.toString(),
      country,
      zip,
    };

    return this.$post<Stripe.Response<Stripe.Invoice>>(
      'api/payments/purchase-testing-license/preview',
      licensePurchaseRequestBody
    );
  }

  executeTestingLicensePayment(
    quantity: number,
    startDate: Date,
    country: string,
    zip: string,
    token: string
  ) {
    const testLicensePurchaseRequestBody: TestingLicensePurchaseRequestBody = {
      quantity,
      startDate: startDate.toString(),
      country,
      zip,
      token,
    };
    return this.$post<TestingLicense>(
      'api/payments/purchase-testing-license',
      testLicensePurchaseRequestBody
    ).then((testingLicense) => {
      const institution = this.root.teachesForInstitutions.find(
        (i) => i.id === testingLicense.userId
      );
      if (institution) {
        institution.addOrUpdateTestingLicense(testingLicense);
      } else {
        // If this is a user's first license, they've just now become an institution.
        return this.refresh().then(() => testingLicense);
      }
      return testingLicense;
    });
  }

  cancelSubscription(subscriptionId: string) {
    return this.$post<Subscription>('api/subscriptions/cancel', { subscriptionId }).then(
      (subscription) => {
        console.log({ subscription });
        this.root.addOrUpdateSubscription(subscription);
        return subscription;
      }
    );
  }

  updateCard(token: string, country: string, zip: string) {
    return this.$post<'ok'>('api/payments/update-card', { token, country, zip });
  }

  fetchInvoices() {
    return this.$get<StripeInvoice[]>('api/payments/invoices', {});
  }

  fetchNextInvoice() {
    return this.$get<StripeInvoice>('api/payments/next-invoice');
  }

  fetchInvoiceHtml(invoiceId: string) {
    return this.$get<string>('api/payments/invoice-html', { invoiceId });
  }

  switchPlan(subscriptionId: string, planId: string) {
    return this.$post<Subscription>('api/subscriptions/switch', { subscriptionId, planId }).then(
      (subscription) => {
        this.root.addOrUpdateSubscription(subscription);
        return subscription;
      }
    );
  }

  closeFlash(flashId: string) {
    return this.$delete<UserWithJoins['flash']>(`api/flashes/${flashId}`).then((flashes) => {
      this.root.setFlashes(flashes);
      return flashes;
    });
  }

  fetchMessage(messageId: string) {
    return this.$get<UMessage>(`api/messages/${messageId}`);
  }

  acceptInvite(messageId: string, accept: boolean) {
    return this.$post<UserWithJoins>('api/sections/invite-response', { messageId, accept }).then(
      (userWithJoins) => {
        this.root.update(userWithJoins);
        return userWithJoins;
      }
    );
  }

  createCheckpointAttempt(checkpointId: string, checkpointName: string, password?: string) {
    this.setProgressSent(true);
    const route = `api/checkpoints/${checkpointId}`;

    return this.$post<Omit<CheckpointResults, 'createdAt' | 'updatedAt'>>(route, {
      checkpointName,
      password,
    }).catch((err: { status: number; message: string }) => {
      if (isObject(err) && err.status === 402) return Promise.reject(err);
      if (isObject(err) && err.status === 403) return Promise.reject(err);
      if (isObject(err) && err.status === 429) return Promise.reject(err);

      return this.ApiQueue.queue<Omit<CheckpointResults, 'createdAt' | 'updatedAt'>>(
        () => this.$post(route, { checkpointName, password }),
        {
          maxRetries: 3,
          delayS: 2,
          actionText: 'creating checkpoint attempt',
          rejectWithoutRetryIf: (error: { status: number; message: string }) =>
            isObject(error) && error.status === 403,
        }
      );
    });
  }

  logCheckpointAttemptQuestion(
    checkpointAttemptId: string,
    data: {
      answerAndData: WeightedAnswerAndData;
      stats: CheckpointStats;
    }
  ) {
    this.setProgressSent(true);
    const route = `api/checkpoints/log/${checkpointAttemptId}`;
    return this.$post<'Question saved.'>(route, data).catch(() =>
      this.ApiQueue.queue<'Question saved.'>(() => this.$post(route, data), {
        actionText: 'saving checkpoint question',
        triesBeforeWarning: 3,
      })
    );
  }

  finishCheckpointAttempt(checkpointAttemptId: string, data: CheckpointStats) {
    this.setProgressSent(true);
    const route = `api/checkpoints/${checkpointAttemptId}`;
    return this.$put<Omit<CheckpointResults, 'questions'>>(route, data)
      .catch(() =>
        this.ApiQueue.queue<Omit<CheckpointResults, 'questions'>>(() => this.$put(route, data), {
          actionText: 'saving checkpoint attempt',
        })
      )
      .then((results) => {
        const attemptStore = new TestAttemptStore(results, this.root);
        this.root.addOrUpdateTestAttempt(attemptStore);
        return results;
      });
  }

  /**
   * Load a complete checkpointAttempt, with questions (and optional user's name info)
   */
  loadCheckpointAttempt(
    checkpointAttemptId: string,
    joinOptions: { user?: boolean } = {},
    rootUser: UserStore | ClientUserStore = this.root
  ) {
    const existingAttempt = rootUser.testAttempts.find(
      (attempt) => attempt.id === checkpointAttemptId
    );

    const params = { attemptId: checkpointAttemptId, ...joinOptions };
    return this.$get<CheckpointResults>('api/checkpoints/', params).then((results) => {
      if (existingAttempt) {
        existingAttempt.update(results);
        return existingAttempt;
      }
      const newAttempt = new TestAttemptStore(results, rootUser);
      rootUser.addOrUpdateTestAttempt(newAttempt);
      return newAttempt;
    });
  }

  deleteCheckpointAttempt(checkpointAttemptId: string) {
    const route = `api/checkpoints/${checkpointAttemptId}`;
    return this.$delete<'OK'>(route).then(() => this.root.removeTestAttempt(checkpointAttemptId));
  }

  /**
   * @param {string} checkpointName
   * @param {string?} duplicateCustomCheckpointId
   * @returns {Promsie.<object>}
   */
  createCustomCheckpoint(
    checkpointName: string,
    duplicateCustomCheckpointId: string | null = null
  ) {
    if (!checkpointName || !isString(checkpointName)) {
      return Promise.reject('A checkpoint name is required.');
    }
    let route = 'api/checkpoints/custom-checkpoints/';
    const params = { checkpointName, duplicateCustomCheckpointId };
    route += encodeURIComponent(checkpointName);
    return this.$post<SavedCustomCheckpoint>(route, params)
      .catch(() =>
        this.ApiQueue.queue<SavedCustomCheckpoint>(() => this.$post(route, params), {
          maxRetries: 2,
          actionText: 'creating custom test',
        })
      )
      .then((savedCheckpoint) => {
        this.root.addOrUpdateTest(savedCheckpoint);
        return this.root.testsById[savedCheckpoint.id]!;
      });
  }

  deleteCustomCheckpoint(checkpointId: string) {
    const route = `api/checkpoints/custom-checkpoints/${checkpointId}`;
    return this.$delete<'OK'>(route)
      .catch(() =>
        this.ApiQueue.queue<'OK'>(() => this.$delete(route), {
          maxRetries: 2,
          actionText: 'deleting custom test',
        })
      )
      .then((ok) => {
        // On the server it's been removed from all users & sections, so we'll
        // mirror that here:
        this.root.allSections.forEach((s) => {
          s.removeTest(checkpointId);
        });
        this.root.removeTest(checkpointId);
        return ok;
      });
  }

  fetchSectionWithCount(sectionId: string) {
    return this.$get<SectionRecordWithCount>(`api/sections/${sectionId}?count=true`);
  }

  fetchStudentFromSection(studentId: string, sectionId: string) {
    return this.$get<StudentUserRecord>(`api/sections/${sectionId}/users/${studentId}`).then(
      (studentRecord) => {
        const section = this.root.taughtSections.find((s) => s.id === sectionId);
        if (!section) {
          throw Error('Students can only be fetched for a section you teach.');
        }
        section.addOrUpdateStudent(studentRecord);
        const student = section.getStudentUserRecord(studentId);
        if (!student) return Promise.reject('Student was not added to section!');
        const studentUserStore = section.students.find((s) => s.id === studentRecord.id);
        if (!studentUserStore) {
          return Promise.reject('Student was not added to section!');
        }
        return studentUserStore;
      }
    );
  }

  fetchGoogleCourses() {
    return this.$get<GoogleClassroomCourseWithLMSConnection[]>('api/sections/google-courses');
  }

  fetchCleverSections() {
    return this.$get<CleverSectionWithLMSConnection[]>('api/sections/clever-sections');
  }

  resyncCleverSectionRosters() {
    return this.$post('api/sections/clever-sections/resync');
  }

  setStudentSectionAccommodation(
    studentId: string,
    sectionId: string,
    accommodation: Pick<Accommodation, 'time' | 'accuracy'>
  ) {
    return this.$put<Accommodation[]>(
      `api/sections/${sectionId}/users/${studentId}/accommodations`,
      accommodation
    ).then((accommodations) => {
      if (studentId === this.root.id) {
        runInAction(() => {
          this.root.accommodations = accommodations;
        });
      }

      const section = this.root.taughtSections.find((s) => s.id === sectionId);
      if (!section) return accommodations;
      const studentRecord = section.getStudentUserRecord(studentId);
      if (!studentRecord) return accommodations;
      section.updateStudent({
        id: studentId,
        accommodations,
      });
      return accommodations;
    });
  }

  /**
   * Fetch full data for each student in a section.
   *
   * @param {Section} section
   * @param {Function=} callback called back on each student received.
   * @param {boolean=} doNotOpenSocket
   * @returns {StudentFetcher}
   */
  fetchStudentsViaSocket(sectionId: string) {
    const url = toURLString(this.webSocketUrl, `api/sections/${sectionId}/users`);

    const section = this.root.taughtSections.find((s) => s.id === sectionId);
    if (!section) {
      throw Error('Students can only be fetched for a section you teach.');
    }
    const sh = new SocketDispatcher(url);

    sh.on('userRecord', (userRecord: StudentUserRecord) => {
      section.addOrUpdateStudent(userRecord);
    });

    sh.on('studentsUpdatedAt', (timeStamp: number) => {
      section.setStudentsUpdatedAt(new Date(timeStamp));
    });

    sh.on('done', () => {
      sh.close();
      section.setStudentLoadingStatus('loaded');
    });
    sh.on('error', (err: any) => section.setStudentLoadingError(err));
    sh.open();
    section.setStudentLoadingStatus('loading');
  }

  /**
   * Fetch full data for each student in a section.
   *
   * @param {Section} section
   * @param {Function=} callback called back on each student received.
   * @param {boolean=} doNotOpenSocket
   * @returns {StudentFetcher}
   */
  refreshStudentsViaSocket(sectionId: string) {
    const section = this.root.taughtSections.find((s) => s.id === sectionId);
    if (!section) {
      throw Error('Students can only be fetched for a section you teach.');
    }

    if (typeof window === 'undefined') return;

    if (section.studentLoadingStatus === 'loading' || section.studentLoadingStatus === 'refreshing')
      return;

    const lastUpdate = section.studentsUpdatedAt?.valueOf() || new Date('2010-01-01').valueOf();
    console.log({ lastUpdate: section.studentsUpdatedAt || '2010' });

    const url = toURLString(
      this.webSocketUrl,
      `api/sections/${sectionId}/users/refresh/${lastUpdate}`
    );

    const sh = new SocketDispatcher(url);

    sh.on('userRecord', (userRecord: StudentUserRecord) => {
      section.addOrUpdateStudent(userRecord);
    });

    sh.on('studentsUpdatedAt', (timeStamp: number) => {
      section.setStudentsUpdatedAt(new Date(timeStamp));
    });

    sh.on('done', () => {
      sh.close();
      section.setStudentLoadingStatus('loaded');
    });
    sh.on('error', (err: any) => section.setStudentLoadingError(err));
    sh.open();
    section.setStudentLoadingStatus('refreshing');
  }

  updateSection(sectionId: string, sectionRecord: Partial<SectionRecordWithJoins>) {
    const route = `api/sections/${sectionId}`;
    return this.ApiQueue.queue<Partial<SectionRecordWithJoins>>(
      () => this.$put(route, sectionRecord),
      {
        actionText: 'saving section',
        triesBeforeWarning: 1,
        maxRetries: 3,
      }
    ).then((savedSectionRecord) => {
      const section = this.root.allSections.find((s) => s.id === savedSectionRecord.id);
      if (!section) return savedSectionRecord;
      if (savedSectionRecord.tests) {
        section.updateTests(savedSectionRecord.tests, savedSectionRecord.connectedTests);
      }
      return savedSectionRecord;
    });
  }

  createSection({
    sectionName,
    copySectionId,
    googleCourseId,
    cleverSectionId,
    hideAssignments = false,
  }: {
    sectionName: string;
    copySectionId?: string;
    googleCourseId?: string;
    cleverSectionId?: string;
    hideAssignments?: boolean;
  }) {
    if (!sectionName || !isString(sectionName)) {
      return Promise.reject('A section name is required.');
    }
    const route = 'api/sections';
    const params = {
      sectionName,
      copySection: copySectionId,
      googleCourseId,
      cleverSectionId,
      hideAssignments,
    };
    return this.$post<SectionRecord>(route, params)
      .then((section) => {
        this.root.addSection(new SectionStore(section, this.root));
        return section;
      });
  }

  /**
   * Delete an owned section.
   * @param sectionId - the section id to delete
   * @param newSectionId - the section id to move students to. If omitted, students
   * are deleted from the section.
   */
  deleteSection(sectionId: string, newSectionId?: string) {
    if (!sectionId || !isString(sectionId)) {
      return Promise.reject('sectionId must be a string.');
    }

    let url = `api/sections/${sectionId}`;
    if (newSectionId) url += `/${newSectionId}`;

    const params: { sectionId: string; relocateStudentsTo?: string } = { sectionId };
    if (newSectionId) params.relocateStudentsTo = newSectionId;
    return this.$delete<string>(url).then((str) => {
      runInAction(() => {
        // GCR TODO: Trigger update the sectionIds properties on the student users.
        const sectionIndex = this.root.allSections.findIndex((s) => s.id === sectionId);
        if (sectionIndex !== -1) {
          this.root.allSections.splice(sectionIndex, 1);
        }
      });
      return str;
    });
  }

  sendSectionInvite(sectionId: string, email: string) {
    const emails = [email];
    return this.$post('api/sections/send-invites', { sectionId, emails });
  }

  /**
   * Changes students' enrollments. If a toSectionId is provided, students are added to that section.
   * If a fromSectionId is provided, students are removed from that section, and added to toSectionId
   * or to the default section, if removing them from their current section would leave them without
   * any section.
   * @param userIds
   * @param options
   */
  moveStudents(userIds: string[], options: { fromSectionId?: string; newSectionId?: string }) {
    return this.$post<(SectionRecord & { students: StudentUserRecord[] })[]>(
      'api/sections/move-users',
      { ...options, userIds }
    ).then(() => {
      const { fromSectionId, newSectionId } = options;

      runInAction(() => {
        // If from & new sections are set, we can execute a move locally:
        if (fromSectionId && newSectionId) {
          const fromSection = this.root.taughtSections.find((s) => s.id === fromSectionId);
          const toSection = this.root.taughtSections.find((s) => s.id === newSectionId);
          // This should never happen.
          if (!fromSection || !toSection) return;
          const users = userIds
            .map((userId) => fromSection.getStudentUserRecord(userId))
            .filter((truthy): truthy is StudentUserRecord => !!truthy);
          users.forEach((user) => {
            fromSection.removeStudent(user);
            toSection.addOrUpdateStudent(user);
          });
          return;
        }

        // If only a from section is set, we can remove the student from that class:
        if (fromSectionId) {
          const fromSection = this.root.taughtSections.find((s) => s.id === fromSectionId);
          // This should never happen.
          if (!fromSectionId) return;
          const users = userIds
            .map((userId) => fromSection?.getStudentUserRecord(userId))
            .filter((truthy): truthy is StudentUserRecord => !!truthy);
          users.forEach((user) => {
            fromSection?.removeStudent(user);
          });
          return;
        }

        // If only a newSectionId is set, we have to clone the students and add them to the new class:
        if (newSectionId) {
          const toSection = this.root.taughtSections.find((s) => s.id === newSectionId);
          // This should never happen.
          if (!toSection) return;
          const users = userIds
            .map((userId) => this.root.getStudentUserRecord(userId))
            .filter((truthy): truthy is StudentUserRecord => !!truthy);
          users.forEach((userRecord) => {
            toSection.addStudent(userRecord);
          });
        }
      });
    });
  }

  joinSectionByInviteLink(
    inviteLink: string
  ): Promise<
    Pick<UserWithJoins, 'currentSections' | 'sectionId' | 'sectionIds' | 'isPaidByGroupLicense'>
  > {
    return this.$put<
      Pick<UserWithJoins, 'currentSections' | 'sectionId' | 'sectionIds' | 'isPaidByGroupLicense'>
    >(`api/sections/join/${inviteLink}`).then((user) => {
      runInAction(() => {
        user.currentSections.forEach((section) => {
          this.root.addSection(new SectionStore(section));
        });
        this.root.changeSection(user.sectionId);
        this.root.isPaidByGroupLicense = user.isPaidByGroupLicense;
      });
      return user;
    });
  }

  /**
   * Verify that we are actually logged in as the user we BELIEVE ourselves to be logged
   * in as.
   */
  isLoggedInAsCurrentUser(): Promise<{
    isLoggedInAsCurrentUser: boolean;
    userId?: string;
    anonymous?: boolean;
  }> {
    return this.$get<{
      isLoggedInAsCurrentUser: boolean;
      userId?: string;
      anonymous?: boolean;
    }>(`auth/is-logged-in-as/${this.root.id}`);
  }

  // changeStudentPassword(studentId, password, password2) {
  //   return this.$put(`api/users/${studentId}`, { password, password2 });
  // }

  getCustomCheckpointResults(checkpointId: string): SocketDispatcher {
    const url = toURLString(
      this.webSocketUrl,
      `api/checkpoints/custom-checkpoint-stream/${checkpointId}/results`
    );
    return new SocketDispatcher(url);
  }

  /**
   * Load a complete checkpointAttempt, with questions (and optional user's name info)
   */
  getCustomCheckpointResult(checkpointAttemptId: string) {
    const params = { attemptId: checkpointAttemptId, join: 'user' };
    return this.$get<CustomTestResultsWithNames>('api/checkpoints', params).then(
      (results) => new RootlessTestAttemptStore(results)
    );
  }

  getStudentsPaidByUser() {
    return this.$get<number>('api/sections/students-paid-by-institution');
  }

  // /**
  //  * Returns a count of how many unique users have taken a custom test in the time frame.
  //  *
  //  * @param {Date} startDate
  //  * @returns {Promise<number>}
  //  */
  // getTestTakersPaidByUser(startDate) {
  //   const isoDate = encodeURIComponent(startDate.toISOString());
  //   return this.$get(`api/checkpoints/custom-checkpoints/licenses/usage/${isoDate}`);
  // }

  getPendingInstitutionTeacherInvites() {
    return this.$get<InstitutionInvite[]>('api/institutions/invites');
  }

  inviteTeacherToInstitution(email: string, institutionId: string) {
    return this.$post<InstitutionInvite>('api/institutions/invites', { email, institutionId });
  }

  deleteInstitutionInvitation(inviteId: string) {
    return this.$delete(`api/institutions/invites/${inviteId}`);
  }

  deleteInstitutionTeacher(
    institutionId: string,
    teacherToDeleteId: string,
    teacherToTakeOverClassesId: string = ''
  ) {
    let url = `api/institutions/${institutionId}/teachers/${teacherToDeleteId}`;
    if (teacherToTakeOverClassesId) {
      url += `?new-teacher=${teacherToTakeOverClassesId}`;
    }
    return this.$delete(url).then(() => {
      const institution = this.root.teachesForInstitutions.find((i) => i.id === institutionId);
      if (!institution) {
        // this should never happen
        return;
      }
      runInAction(() => {
        institution.teachers = institution.teachers.filter((t) => t.id !== teacherToDeleteId);
      });
    });
  }

  getLicensePrices() {
    return this.$get<LicenseRouterPricingResponse>('/pricing/licenses/include-durations');
  }

  getTestingLicensePrices() {
    return this.$get<TestingLicenseRate[]>('/pricing/testing-licenses');
  }

  getLicenseUsage(noRefreshAllowed = false): Promise<LicenseUsage[]> {
    return this.$get<LicenseUsage[]>('api/licenses/usage').then((usages) => {
      let refreshNeeded = false;
      usages.forEach((usage) => {
        const institution = this.root.teachesForInstitutions.find((i) => i.id === usage.userId);
        // This could happen if a user was added to an institution in the time since
        // they'd last refreshed the page.
        if (!institution && !noRefreshAllowed) {
          refreshNeeded = true;
        } else {
          institution?.updateUsage(usage);
        }
      });
      return refreshNeeded ? this.refresh().then(() => this.getLicenseUsage(true)) : usages;
    });
  }
}
